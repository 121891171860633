<template>
  <div class="JoinStudyFundSummary">

    <div class="stat-data" v-if="summary_data">
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.TotalFee}}</p>
                <p class="stat-text-title">总基金</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.Consumed}}</p>
                <p class="stat-text-title">已消耗</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.SurplusFee}}</p>
                <p class="stat-text-title">剩余基金</p>
            </div>
        </div>
    </div>

    <div class="search-item">
        <a-tree-select
            style="width: 250px;margin-right: 10px;"
            v-model="OrgIds"
            :tree-data="treeData"
            tree-checkable
            :maxTagCount="1"
            placeholder="请选择搜索范围"
            @change="onOrgChange"
            :show-checked-strategy="SHOW_PARENT"
          />

        <!-- <a-range-picker :ranges="RangesConf" @change="onDateChange" style="margin-right: 10px;" :defaultValue="defaultDate"/> -->

        <!-- <a-input type="text" v-model="Keyword" placeholder="搜索名称" style="width: 150px;margin-right: 10px;"/> -->

        <a-button style="margin-right: 10px;" @click="reset">重 置</a-button>

        <a-button type="primary" @click="_search">搜 索</a-button>
    </div>
	<div class="table-list">
        <a-table :columns="columns" :data-source="dataList" :pagination="false"></a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import * as echarts from 'echarts'
export default {
	name: 'JoinStudyFundSummary',
    components: { echarts },
    data () {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:[],
            SelectDataList:[],
            func:'',
            columns:[
                {
                    title: '序号',
                    dataIndex: 'Num',
                    key: 'Num',
                },
                {
                    title: '城市',
                    dataIndex: 'City',
                    key: 'City',
                },
                {
                    title: '基金总额',
                    dataIndex: 'TotalFee',
                    key: 'TotalFee',
                },
                {
                    title: '已消耗',
                    dataIndex: 'Consumed',
                    key: 'Consumed',
                },
                {
                    title: '剩余金额',
                    dataIndex: 'SurplusFee',
                    key: 'SurplusFee',
                }
            ],
            shade:false,
            CurrPlayUrl:'',
            treeData: [],
            SchoolIds:[],
            SHOW_PARENT: 'SHOW_ALL',
            OnlineClassNetData:'',
            OnlineClassNetVisible:false,
            OnlineClassDeviceData:'',
            OnlineClassDeviceVisible:false,
            _CurrShowStr:'',
            _NetData:[],
            summary_data:'',
            Keyword:'',
            OrderField:'_id',
            Order:'desc',
            TeacherName:'',
        }
    },
    props: ['queryData'],
    watch: {
        queryData: {
            immediate: true,
            handler (val) {

            },
            deep: true
        }
    },
    created () {

        // this.RangesConf = {
        //     '上上月': [
        //         moment().subtract(2,'months').startOf('month'),
        //         moment().subtract(2,'months').endOf('month')
        //     ],
        //     '上月':[
        //         moment().subtract(1,'months').startOf('month'),
        //         moment().subtract(1,'months').endOf('month')
        //     ],
        //     '本月':[
        //         moment().startOf("month"),
        //         moment().endOf('month')
        //     ]
        // }

        // // this.StartDate = moment().week(moment().week()).startOf('month-1').format('YYYY-MM-DD');
        // this.EndDate   = moment().format('YYYY-MM-DD');
        // this.StartDate = moment(this.EndDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

        // this.defaultDate = [this.StartDate,this.EndDate];

        this._treeData();

        this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
            this.Keyword = '';
            this.TeacherName = '';
            this.OrgIds = [];
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'JoinStudyFund|JoinStudyFund|summary',
                    OrgIds:this.OrgIds,
                    Page:this.currPage,
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                        this.summary_data = res.data.data.summary_data;
                    } else {
                        this.dataList = [];
                        this.DataCount = 0;
                        this.summary_data = res.data.data.summary_data;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        _onCloseVideo(){
            this.shade = false;
            this.CurrPlayUrl = '';
            this.$refs.video.pause();
        },
        SeePlayBack(url){
            this.shade = true;
            this.CurrPlayUrl = url;
        },
        _treeData () {
          let self = this
          this.$axios.get(
            1090,
            {
              UserId: self.userinfo.uid
            },
            res => {
              self.treeData = res.data
            }
          )
        },
        onOrgChange(value, label, text){
            if (text.checked) {
                this.SchoolIds.push(text.triggerValue)
                } else {
                if (this.SchoolIds.length > 0) {
                  for (var i = 0; i < this.SchoolIds.length; i++) {
                    if (text.triggerValue == this.SchoolIds[i]) {
                      this.SchoolIds.splice(i, 1)
                    }
                  }
                }
            }

            this.OrgName = label
            this.OrgIds = value
            console.log(this.SchoolIds);
            console.log(this.OrgIds);
        },
        _StudentList(d){
            console.log(d);
        },
        onCloseOnlineClassNet(){
            this.OnlineClassNetVisible = false;
        },
        _showOnlineClassNet(d){
            console.log(d)
            this.OnlineClassNetVisible = true;
            this.OnlineClassNetData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        onCloseOnlineClassDevice(){
            this.OnlineClassDeviceVisible = false;
        },
        _showOnlineClassDevice(d){
            this.OnlineClassDeviceVisible = true;
            this.OnlineClassDeviceData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        _showEchart(record,str,e){
            let that = this;

            that.CurrShowStr = str;

            that.Num = record.ClassinId;

            if (e) { that._getNetCpuData(record,e); }
        },
    }
}
</script>

<style lang="less" scoped>
.JoinStudyFundSummary {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #FFF;
    padding: 15px;
}


.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.table-list{
    margin-top: 30px;
    margin-left: 20px;
}

.shade{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    z-index: 99999;
}
.seevideo{
    position: absolute;
    top: 1%;
    left: 49%;
    transform: translate(-50%, 50%);
    z-index: 999999;
}


.stat-data{
    width: 80%;
    background-color: #eff6ff;
    margin-left: 20px;
    height:150px;
    padding:30px;
    margin-top: 20px;
}

.stat-item{
    width: 25%;
    display: -ms-flexbox;
    height: 90px;
    background-color: #ffffff;
    margin-right: 20px;
    display: inline-block;
}
.stat-text{
    margin: 25px;
}
.stat-num{
    font-weight: 600;
    font-size:18px;
}
.stat-text-title{
    font-weight: 300;
    font-size: 12px;
}
</style>
